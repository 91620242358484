const msgs = [

    {
        id: 0,
        name: "Ionic Framework",
        avatar: "https://pbs.twimg.com/profile_images/1148952014036054016/xxv7lLvp_400x400.jpg",
        message: "This is a test message. This is a test message. This is a test message. ",
        last_message_sent: 3,
        new_message_count: 2
    },
    {
        id: 1,
        name: "Capacitor JS",
        avatar: "https://pbs.twimg.com/profile_images/1268235262641004544/OLW1xl7t_400x400.png",
        message: "",
        last_message_sent: 9,
        new_message_count: 0
    },
    {
        id: 2,
        name: "Max Lynch",
        avatar: "https://pbs.twimg.com/profile_images/1318970727173885953/bln98FNj_400x400.jpg",
        message: "",
        last_message_sent: 15,
        new_message_count: 0
    },
    {
        id: 3,
        name: "Ben Sperry",
        avatar: "https://pbs.twimg.com/profile_images/1328390491126308864/jHHgl5Dm_400x400.jpg",
        message: "",
        last_message_sent: 27,
        new_message_count: 0
    },
    {
        id: 4,
        name: "Matt Netkow",
        avatar: "https://pbs.twimg.com/profile_images/1323383930150621187/GKc0nVzi_400x400.jpg",
        message: "",
        last_message_sent: 31,
        new_message_count: 1
    },
    {
        id: 5,
        name: "Liam DeBeasi",
        avatar: "https://pbs.twimg.com/profile_images/1105953692669366273/ZNK4lRAJ_400x400.jpg",
        message: "",
        last_message_sent: 41,
        new_message_count: 0
    },
    {
        id: 6,
        name: "Mike Hartington",
        avatar: "https://pbs.twimg.com/profile_images/1084993841898446849/DJ8XtR6L_400x400.jpg",
        message: "",
        online: false,
        last_message_sent: 47,
        new_message_count: 0
    },
    {
        id: 7,
        name: "Adam Bradley",
        avatar: "https://pbs.twimg.com/profile_images/909075942320025600/hfYqicUk_400x400.jpg",
        message: "",
        last_message_sent: 51,
        new_message_count: 0
    },
    {
        id: 8,
        name: "Brody Kidd",
        avatar: "https://pbs.twimg.com/profile_images/477539679567228928/JObyaUW__400x400.jpeg",
        message: "",
        last_message_sent: 53,
        new_message_count: 0
    }
];

export const getMessages = () => msgs;