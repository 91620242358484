import { IonAvatar, IonButton, IonButtons, IonCol, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonHeader, IonIcon, IonImg, IonItem, IonPage, IonSearchbar, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { searchOutline, chatboxOutline, playCircleOutline, bagOutline, homeOutline, menuOutline, flagOutline, notificationsOutline, homeSharp, home, videocam, images, globe, ellipse, ellipsisHorizontal, thumbsUp, heart, thumbsUpOutline, shareOutline, arrowRedoOutline, addCircle  } from "ionicons/icons";
import ExploreContainer from '../../components/ExploreContainer';
import Post from './Post';
//import { messages, posts } from '../data/messages';
//import './Feed.css';
import styles from "./Feed.module.scss";

/*export const messages = [

    {
        name: "Ionic Framework",
        avatar: "https://pbs.twimg.com/profile_images/1148952014036054016/xxv7lLvp_400x400.jpg",
        message: "",
        online: false,
        last_message_sent: 3,
        new_message_count: 2
    },
    {
        name: "Capacitor JS",
        avatar: "https://pbs.twimg.com/profile_images/1268235262641004544/OLW1xl7t_400x400.png",
        message: "",
        online: true,
        last_message_sent: 9,
        new_message_count: 0
    },
    {
        name: "Max Lynch",
        avatar: "https://pbs.twimg.com/profile_images/1318970727173885953/bln98FNj_400x400.jpg",
        message: "",
        online: true,
        last_message_sent: 15,
        new_message_count: 0
    },
    {
        name: "Ben Sperry",
        avatar: "https://pbs.twimg.com/profile_images/1328390491126308864/jHHgl5Dm_400x400.jpg",
        message: "",
        online: false,
        last_message_sent: 27,
        new_message_count: 0
    },
    {
        name: "Matt Netkow",
        avatar: "https://pbs.twimg.com/profile_images/1323383930150621187/GKc0nVzi_400x400.jpg",
        message: "",
        online: false,
        last_message_sent: 31,
        new_message_count: 1
    },
    {
        name: "Liam DeBeasi",
        avatar: "https://pbs.twimg.com/profile_images/1105953692669366273/ZNK4lRAJ_400x400.jpg",
        message: "",
        online: true,
        last_message_sent: 41,
        new_message_count: 0
    },
    {
        name: "Mike Hartington",
        avatar: "https://pbs.twimg.com/profile_images/1084993841898446849/DJ8XtR6L_400x400.jpg",
        message: "",
        online: false,
        last_message_sent: 47,
        new_message_count: 0
    },
    {
        name: "Adam Bradley",
        avatar: "https://pbs.twimg.com/profile_images/909075942320025600/hfYqicUk_400x400.jpg",
        message: "",
        online: true,
        last_message_sent: 51,
        new_message_count: 0
    },
    {
        name: "Brody Kidd",
        avatar: "https://pbs.twimg.com/profile_images/477539679567228928/JObyaUW__400x400.jpeg",
        message: "",
        online: true,
        last_message_sent: 53,
        new_message_count: 0
    }
];*/

import { useState } from 'react';
import { get, set } from '../../data/IonicStorage';
import { getPosts } from '../../data/posts';

type PostParams = {
    id: number;
    avatar: string;
    name: string;
    message: string;
    sponsored: boolean;
    time: string;
    views: string;
    online: boolean;
    image?: string;
}

const Feed: React.FC = () => {
	const [data, setData] = useState<string[]>([]);
	const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
	const [posts, setPosts] = useState<PostParams[]>([]);

	useIonViewWillEnter(async() => {
		const exists = await get("posts");

		if (!exists) {
			const msgs = getPosts();
			set("posts", msgs);
			setPosts(msgs);
		} else {
			setPosts(exists);
		}
	});

	const loadData = (e: any) => {
		resetStore();
		setTimeout(() => {
			e.target.complete();
      if (data.length == 1000) {
        setInfiniteDisabled(true);
      }
		}, 500);
	};

	const resetStore = async () => {
		const msgs = getPosts();
		set("posts", msgs);
		setPosts(msgs);
	}

	return (
		<IonPage className={ styles.home }>
			<IonHeader>
				<IonToolbar>
					<IonCol size="12">
						<IonItem lines="none" >
							<IonAvatar slot="start" style={{ height: "2.5rem", width: "2.5rem" }}>
								<IonImg src="https://pbs.twimg.com/profile_images/1349059994747076610/8dWvipvu_400x400.jpg" />
							</IonAvatar>

							<IonSearchbar placeholder="What's on your mind?" searchIcon={ addCircle }/>
						</IonItem>
					</IonCol>

					<IonButtons slot="end" className={ styles.toolbarIcons }>
						<IonIcon icon={ searchOutline } size="small"/>
						<IonIcon icon={ chatboxOutline } size="small"/>
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<div className={ styles.topHeader }></div>

				{ posts.map((post, index) => {
					return <Post post={ post } key={ index } />;
				})}

				<IonInfiniteScroll
				onIonInfinite={loadData}
				threshold="100px"
				position="bottom"
				disabled={isInfiniteDisabled}
				>
				<IonInfiniteScrollContent
					loadingSpinner="bubbles"
					loadingText="Loading more data..."
				></IonInfiniteScrollContent>
				</IonInfiniteScroll>

			</IonContent>
		</IonPage>
	);
};

export default Feed;

/*

				<IonRow className="ion-no-padding ion-no-margin post">
					<IonCol size="12" className="ion-no-padding ion-no-margin">
						<div className="post-container">
							<IonItem lines="none" className="post-header ion-no-margin ion-no-padding">
								<IonAvatar slot="start" className="ion-no-padding ion-no-margin">
									<IonImg src="https://pbs.twimg.com/profile_images/1148952014036054016/xxv7lLvp_400x400.jpg" />
								</IonAvatar>

								<IonLabel>
									<h3>Ionic Framework</h3>
									<p>
										Sponsored
										&nbsp;&nbsp;
										<IonIcon icon={ globe } />
									</p>
								</IonLabel>

								<IonIcon icon={ ellipsisHorizontal } />
							</IonItem>

							<IonItem lines="none" className="post-content ion-no-margin ion-no-padding">
								<p>Build cross-platform web native mobile apps with one codebase! 🎉</p>
							</IonItem>

							{/ <IonItem lines="none" className="post-image ion-no-margin ion-no-padding"> /}
							<IonImg src="https://miro.medium.com/max/1200/1*Wr5pn3g1pMkibZi8V5MynA.jpeg" />
							{/ </IonItem> /}

							<div className="post-link ion-no-margin ion-no-padding">
								<IonLabel>
									<p>ionicframework.com</p>
									<h3>Start building apps today!</h3>
								</IonLabel>

								<IonButton>
									Learn more
								</IonButton>
							</div>

							<div className="post-likes ion-no-margin ion-no-padding">
								<div className="post-like-icons">
									<IonIcon icon={ thumbsUp } />
									<IonIcon icon={ heart } />
								</div>

								<p>16K Views</p>
							</div>

							<div className="post-actions ion-no-margin ion-no-padding ion-text-center">

								<IonCol size="4">
									<IonIcon icon={ thumbsUpOutline } />
									<IonText>Like</IonText>
								</IonCol>

								<IonCol size="4">
									<IonIcon icon={ chatboxOutline } />
									<IonText>Comment</IonText>
								</IonCol>

								<IonCol size="4">
									<IonIcon icon={ arrowRedoOutline } />
									<IonText>Share</IonText>
								</IonCol>
							</div>
						</div>
					</IonCol>
				</IonRow>

				*/