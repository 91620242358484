import { 
    IonBackButton, 
    IonButton, 
    IonButtons, 
    IonContent, 
    IonFab, 
    IonFabButton, 
    IonHeader, 
    IonIcon, 
    IonInfiniteScroll, 
	IonInfiniteScrollContent,
    IonList, 
    IonPage, 
    IonTitle, 
	useIonViewWillEnter, 
    IonToolbar } from '@ionic/react';
import { addOutline, searchOutline } from 'ionicons/icons';
import ExploreContainer from './ExploreContainer';
import MessageItem from './MessageItem';
import './Messages.css';
import { useState } from 'react';
import { get, set } from '../../data/IonicStorage';
import { getMessages } from '../../data/messages';

type MessageItemParams = {
	id: number;
    avatar?: string;
    name?: string;
    message?: string;
    last_message_sent?: number;
    new_message_count: number;
}

const Messages: React.FC = () => {
	const [data, setData] = useState<string[]>([]);
	const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
	const [msgs, setMessages] = useState<MessageItemParams[]>([]);

	useIonViewWillEnter(async() => {
		const exists = await get("msgs");

		if (!exists) {
			const msgs = getMessages();
			set("msgs", msgs);
			setMessages(msgs);
		} else {
			setMessages(exists);
		}
	});

	const loadData = (e: any) => {
		resetStore();
		setTimeout(() => {
			e.target.complete();
      if (data.length == 1000) {
        setInfiniteDisabled(true);
      }
		}, 500);
	};

	const resetStore = async () => {
		const msgs = getMessages();
		set("msgs", msgs);
		setMessages(msgs);
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Messages</IonTitle>
					<IonButtons slot="start">
						<IonBackButton text=" " defaultHref="/timeline" />
						<IonButton slot="start">
							<img className="profile-avatar" src="https://pbs.twimg.com/profile_images/1268235262641004544/OLW1xl7t_400x400.png" />
						</IonButton>
						<span className="profile-name">@93alan</span>
					</IonButtons>
					<IonButtons slot="end">
						<IonButton slot="end">
							<IonIcon className="search" icon={ searchOutline } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			
			<IonContent fullscreen>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">Messages</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonList>
					{
					  msgs && msgs.map((message, index) => <MessageItem key={ index } message={ message } /> )
					}
				</IonList>

				<IonInfiniteScroll
				onIonInfinite={loadData}
				threshold="100px"
				position="bottom"
				disabled={isInfiniteDisabled}
				>
				<IonInfiniteScrollContent
					loadingSpinner="bubbles"
					loadingText="Loading more data..."
				></IonInfiniteScrollContent>
				</IonInfiniteScroll>

				<IonFab className="add-fab" vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton>
						<IonIcon icon={ addOutline } />
					</IonFabButton>
				</IonFab>

			</IonContent>
		</IonPage>
	);
};

export default Messages;